<template>
  <div>
    <div class="font-size-md mt-5 welcomeText" style="color: #01041b">
      {{ getWelcomeText() }}
      <br />
      <small>{{ getUserRoleText() }}</small>
    </div>

    <b-skeleton-wrapper :loading="!currentUser || !currentUser.user_group">
      <template #loading>
        <b-card class="card card-custom gutter-b">
          <b-skeleton width="85%"></b-skeleton>
          <b-skeleton width="55%"></b-skeleton>
          <b-skeleton width="70%"></b-skeleton>
        </b-card>
      </template>

      <ul class="menu-nav">
        <router-link
          to="/"
          v-slot="{ href, navigate, isActive, isExactActive }"
        >
          <li
            aria-haspopup="true"
            data-menu-toggle="hover"
            class="menu-item"
            :class="[
              $route.path == '/' && isActive && 'menu-item-active',
              $route.path == '/' && isExactActive && 'menu-item-active',
            ]"
          >
            <a :href="href" class="menu-link" @click="navigate">
              <i class="menu-icon flaticon-home"></i>
              <span class="menu-text">Ana Sayfa </span>
            </a>
          </li>
        </router-link>
        <router-link
          to="/mulkler"
          v-slot="{ href, navigate, isActive, isExactActive }"
        >
          <li
            aria-haspopup="true"
            data-menu-toggle="hover"
            class="menu-item"
            :class="[
              $route.path == '/mulkler' && isActive && 'menu-item-active',
              $route.path == '/mulkler' && isExactActive && 'menu-item-active',
            ]"
          >
            <a :href="href" class="menu-link" @click="navigate">
              <i class="menu-icon flaticon-home"></i>
              <span class="menu-text">Mülkler </span>
            </a>
          </li>
        </router-link>
        <router-link
          to="/musteriler"
          v-slot="{ href, navigate, isActive, isExactActive }"
        >
          <li
            aria-haspopup="true"
            data-menu-toggle="hover"
            class="menu-item"
            :class="[
              $route.path == '/musteriler' && isActive && 'menu-item-active',
              $route.path == '/musteriler' &&
                isExactActive &&
                'menu-item-active',
            ]"
          >
            <a :href="href" class="menu-link" @click="navigate">
              <i class="menu-icon flaticon-home"></i>
              <span class="menu-text">Müşteriler </span>
            </a>
          </li>
        </router-link>
        <router-link
          to="/is-ortaklari"
          v-slot="{ href, navigate, isActive, isExactActive }"
        >
          <li
            aria-haspopup="true"
            data-menu-toggle="hover"
            class="menu-item"
            :class="[
              $route.path == '/is-ortaklari' && isActive && 'menu-item-active',
              $route.path == '/is-ortaklari' &&
                isExactActive &&
                'menu-item-active',
            ]"
          >
            <a :href="href" class="menu-link" @click="navigate">
              <i class="menu-icon flaticon-home"></i>
              <span class="menu-text">İş Ortakları </span>
            </a>
          </li>
        </router-link>
        <router-link
          to="/kiracilar"
          v-slot="{ href, navigate, isActive, isExactActive }"
        >
          <li
            aria-haspopup="true"
            data-menu-toggle="hover"
            class="menu-item"
            :class="[
              $route.path == '/kiracilar' && isActive && 'menu-item-active',
              $route.path == '/kiracilar' &&
                isExactActive &&
                'menu-item-active',
            ]"
          >
            <a :href="href" class="menu-link" @click="navigate">
              <i class="menu-icon flaticon-home"></i>
              <span class="menu-text">Kiracılar </span>
            </a>
          </li>
        </router-link>

        <router-link
          to="/hatirlatmalar"
          v-slot="{ href, navigate, isActive, isExactActive }"
        >
          <li
            aria-haspopup="true"
            data-menu-toggle="hover"
            class="menu-item"
            :class="[
              $route.path == '/hatirlatmalar' && isActive && 'menu-item-active',
              $route.path == '/hatirlatmalar' &&
                isExactActive &&
                'menu-item-active',
            ]"
          >
            <a :href="href" class="menu-link" @click="navigate">
              <i class="menu-icon flaticon-home"></i>
              <span class="menu-text">Hatırlatmalar </span>
            </a>
          </li>
        </router-link>
        <router-link
          to="/paketler/paket/"
          v-slot="{ href, navigate, isActive, isExactActive }"
        >
          <li
            aria-haspopup="true"
            data-menu-toggle="hover"
            class="menu-item"
            :class="[
              $route.path == '/paket' && isActive && 'menu-item-active',
              $route.path == '/paket' &&
                isExactActive &&
                'menu-item-active',
            ]"
          >
            <a :href="href" class="menu-link" @click="navigate">
              <i class="menu-icon flaticon-home"></i>
              <span class="menu-text">Paketler </span>
            </a>
          </li>
        </router-link>
        <router-link
          to="/raporlar/rapor/"
          v-slot="{ href, navigate, isActive, isExactActive }"
        >
          <li
            aria-haspopup="true"
            data-menu-toggle="hover"
            class="menu-item"
            :class="[
              $route.path == '/rapor' && isActive && 'menu-item-active',
              $route.path == '/rapor' &&
                isExactActive &&
                'menu-item-active',
            ]"
          >
            <a :href="href" class="menu-link" @click="navigate">
              <i class="menu-icon flaticon-home"></i>
              <span class="menu-text">Raporlar </span>
            </a>
          </li>
        </router-link>
        <li
          aria-haspopup="true"
          data-menu-toggle="hover"
          class="menu-item menu-item-submenu"
          v-bind:class="{
            'menu-item-open': hasActiveChildren('/manage'),
          }"
        >
          <a href="#" class="menu-link menu-toggle"
            ><i class="menu-icon flaticon-home"></i
            ><span class="menu-text">Takip</span><i class="menu-arrow"></i
          ></a>
          <div class="menu-submenu" kt-hidden-height="240" style="">
            <span class="menu-arrow"></span>
            <ul class="menu-subnav">
              <li aria-haspopup="true" class="menu-item menu-item-parent">
                <span class="menu-link"
                  ><span class="menu-text">Takip</span></span
                >
          </li>
              <router-link
                to="/takip/istakip"
                v-slot="{ href, navigate, isActive, isExactActive }"
              >
                <li
                  aria-haspopup="true"
                  data-menu-toggle="hover"
                  class="menu-item"
                  :class="[
                    isActive && 'menu-item-active',
                    isExactActive && 'menu-item-active',
                  ]"
                >
                  <a :href="href" class="menu-link" @click="navigate">
                    <i class="menu-bullet menu-bullet-dot"><span></span></i>
                    <span class="menu-text">İş Takip  </span>
                  </a>
                </li>
              </router-link>
              <!-- <router-link
                to="/takip/vadetakip"
                v-slot="{ href, navigate, isActive, isExactActive }"
              >
                <li
                  aria-haspopup="true"
                  data-menu-toggle="hover"
                  class="menu-item"
                  :class="[
                    isActive && 'menu-item-active',
                    isExactActive && 'menu-item-active',
                  ]"
                >
                  <a :href="href" class="menu-link" @click="navigate">
                    <i class="menu-bullet menu-bullet-dot"><span></span></i>
                    <span class="menu-text">Vade Takip </span>
                  </a>
                </li>
              </router-link> -->
              <!-- <router-link
                to="/takip/slatakip"
                v-slot="{ href, navigate, isActive, isExactActive }"
              >
                <li
                  aria-haspopup="true"
                  data-menu-toggle="hover"
                  class="menu-item"
                  :class="[
                    isActive && 'menu-item-active',
                    isExactActive && 'menu-item-active',
                  ]"
                >
                  <a :href="href" class="menu-link" @click="navigate">
                    <i class="menu-bullet menu-bullet-dot"><span></span></i>
                    <span class="menu-text">SLA Takip </span>
                  </a>
                </li>
              </router-link> -->
            </ul>
          </div>
        </li>
        <router-link
          to="/sistem-kullanicilari"
          v-slot="{ href, navigate, isActive, isExactActive }"
        >
          <li
            aria-haspopup="true"
            data-menu-toggle="hover"
            class="menu-item"
            :class="[
              isActive && 'menu-item-active',
              isExactActive && 'menu-item-active',
            ]"
          >
            <a :href="href" class="menu-link" @click="navigate">
              <i class="menu-icon flaticon-network"></i>
              <span class="menu-text">Sistem Kullanıcı Listesi</span>
            </a>
          </li>
          
        </router-link>

        <li
          aria-haspopup="true"
          data-menu-toggle="hover"
          class="menu-item menu-item-submenu"
          v-bind:class="{
            'menu-item-open': hasActiveChildren('/manage'),
          }"
        >
          <a href="#" class="menu-link menu-toggle"
            ><i class="menu-icon flaticon-home"></i
            ><span class="menu-text">Ödemeler</span><i class="menu-arrow"></i
          ></a>
          <div class="menu-submenu" kt-hidden-height="240" style="">
            <span class="menu-arrow"></span>
            <ul class="menu-subnav">
              <li aria-haspopup="true" class="menu-item menu-item-parent">
                <span class="menu-link"
                  ><span class="menu-text">Ödemeler</span></span
                >
              </li>
              <router-link
                to="/odemeler/musteri-odemeler"
                v-slot="{ href, navigate, isActive, isExactActive }"
              >
                <li
                  aria-haspopup="true"
                  data-menu-toggle="hover"
                  class="menu-item"
                  :class="[
                    isActive && 'menu-item-active',
                    isExactActive && 'menu-item-active',
                  ]"
                >
                  <a :href="href" class="menu-link" @click="navigate">
                    <i class="menu-bullet menu-bullet-dot"><span></span></i>
                    <span class="menu-text">Müşteri Ödeme </span>
                  </a>
                </li>
              </router-link>
              <router-link
                to="/odemeler/sigorta-odemeler"
                v-slot="{ href, navigate, isActive, isExactActive }"
              >
                <li
                  aria-haspopup="true"
                  data-menu-toggle="hover"
                  class="menu-item"
                  :class="[
                    isActive && 'menu-item-active',
                    isExactActive && 'menu-item-active',
                  ]"
                >
                  <a :href="href" class="menu-link" @click="navigate">
                    <i class="menu-bullet menu-bullet-dot"><span></span></i>
                    <span class="menu-text">Sigorta Ödemeleri </span>
                  </a>
                </li>
              </router-link>
              <router-link
                to="/odemeler/vergi-odemeler"
                v-slot="{ href, navigate, isActive, isExactActive }"
              >
                <li
                  aria-haspopup="true"
                  data-menu-toggle="hover"
                  class="menu-item"
                  :class="[
                    isActive && 'menu-item-active',
                    isExactActive && 'menu-item-active',
                  ]"
                >
                  <a :href="href" class="menu-link" @click="navigate">
                    <i class="menu-bullet menu-bullet-dot"><span></span></i>
                    <span class="menu-text">Vergi Ödemeleri </span>
                  </a>
                </li>
              </router-link>
              <router-link
                to="/odemeler/kiraci-odemeler"
                v-slot="{ href, navigate, isActive, isExactActive }"
              >
                <li
                  aria-haspopup="true"
                  data-menu-toggle="hover"
                  class="menu-item"
                  :class="[
                    isActive && 'menu-item-active',
                    isExactActive && 'menu-item-active',
                  ]"
                >
                  <a :href="href" class="menu-link" @click="navigate">
                    <i class="menu-bullet menu-bullet-dot"><span></span></i>
                    <span class="menu-text">Kiracı Ödeme </span>
                  </a>
                </li>
              </router-link>
              <router-link
                to="/odemeler/aidat-odemeler"
                v-slot="{ href, navigate, isActive, isExactActive }"
              >
                <li
                  aria-haspopup="true"
                  data-menu-toggle="hover"
                  class="menu-item"
                  :class="[
                    isActive && 'menu-item-active',
                    isExactActive && 'menu-item-active',
                  ]"
                >
                  <a :href="href" class="menu-link" @click="navigate">
                    <i class="menu-bullet menu-bullet-dot"><span></span></i>
                    <span class="menu-text">Aidat Ödemeleri</span>
                  </a>
                </li>
              </router-link>
              <router-link
                to="/odemeler/isortaklari-odemeler"
                v-slot="{ href, navigate, isActive, isExactActive }"
              >
                <li
                  aria-haspopup="true"
                  data-menu-toggle="hover"
                  class="menu-item"
                  :class="[
                    isActive && 'menu-item-active',
                    isExactActive && 'menu-item-active',
                  ]"
                >
                  <a :href="href" class="menu-link" @click="navigate">
                    <i class="menu-bullet menu-bullet-dot"><span></span></i>
                    <span class="menu-text">İş Ortakları Ödemeler</span>
                  </a>
                </li>
              </router-link>
            </ul>
          </div>
        </li>
        <li
          aria-haspopup="true"
          data-menu-toggle="hover"
          class="menu-item menu-item-submenu"
          v-bind:class="{
            'menu-item-open': hasActiveChildren('/manage'),
          }"
        >
          <a href="#" class="menu-link menu-toggle"
            ><i class="menu-icon flaticon-home"></i
            ><span class="menu-text">Yönetim</span><i class="menu-arrow"></i
          ></a>
          <div class="menu-submenu" kt-hidden-height="240" style="">
            <span class="menu-arrow"></span>
            <ul class="menu-subnav">
              <li aria-haspopup="true" class="menu-item menu-item-parent">
                <span class="menu-link"
                  ><span class="menu-text">Yönetim</span></span
                >
              </li>
              <!-- <router-link
                to="/yonetim/mulkler"
                v-slot="{ href, navigate, isActive, isExactActive }"
              >
                <li
                  aria-haspopup="true"
                  data-menu-toggle="hover"
                  class="menu-item"
                  :class="[
                    isActive && 'menu-item-active',
                    isExactActive && 'menu-item-active',
                  ]"
                >
                  <a :href="href" class="menu-link" @click="navigate">
                    <i class="menu-bullet menu-bullet-dot"><span></span></i>
                    <span class="menu-text">Mülkler </span>
                  </a>
                </li>
              </router-link> -->
              <!-- <router-link
                to="/yonetim/kiracilar"
                v-slot="{ href, navigate, isActive, isExactActive }"
              >
                <li
                  aria-haspopup="true"
                  data-menu-toggle="hover"
                  class="menu-item"
                  :class="[
                    isActive && 'menu-item-active',
                    isExactActive && 'menu-item-active',
                  ]"
                >
                  <a :href="href" class="menu-link" @click="navigate">
                    <i class="menu-bullet menu-bullet-dot"><span></span></i>
                    <span class="menu-text">Kiracılar </span>
                  </a>
                </li>
              </router-link> -->
              <!-- <router-link
                to="/yonetim/dokuman"
                v-slot="{ href, navigate, isActive, isExactActive }"
              >
                <li
                  aria-haspopup="true"
                  data-menu-toggle="hover"
                  class="menu-item"
                  :class="[
                    isActive && 'menu-item-active',
                    isExactActive && 'menu-item-active',
                  ]"
                >
                  <a :href="href" class="menu-link" @click="navigate">
                    <i class="menu-bullet menu-bullet-dot"><span></span></i>
                    <span class="menu-text">Doküman </span>
                  </a>
                </li>
              </router-link> -->
              <router-link
                to="/yonetim/musteriler/"
                v-slot="{ href, navigate, isActive, isExactActive }"
              >
                <li
                  aria-haspopup="true"
                  data-menu-toggle="hover"
                  class="menu-item"
                  :class="[
                    isActive && 'menu-item-active',
                    isExactActive && 'menu-item-active',
                  ]"
                >
                  <a :href="href" class="menu-link" @click="navigate">
                    <i class="menu-bullet menu-bullet-dot"><span></span></i>
                    <span class="menu-text">Müşteriler </span>
                  </a>
                </li>
              </router-link>
            </ul>
          </div>
        </li>
        <router-link
          to="/teknik-destek"
          v-slot="{ href, navigate, isActive, isExactActive }"
        >
          <li
            aria-haspopup="true"
            data-menu-toggle="hover"
            class="menu-item"
            :class="[
              isActive && 'menu-item-active',
              isExactActive && 'menu-item-active',
            ]"
          >
            <a :href="href" class="menu-link" @click="navigate">
              <i class="menu-icon flaticon-support"></i>
              <span class="menu-text">Teknik Destek</span>
            </a>
          </li>
          
        </router-link>
        <li
          aria-haspopup="true"
          data-menu-toggle="hover"
          class="menu-item"
          :class="[
            isActive && 'menu-item-active',
            isExactActive && 'menu-item-active',
          ]"
        >
          <a class="menu-link" @click="onLogout">
            <i class="menu-icon flaticon-logout"></i>
            <span class="menu-text">Çıkış Yap</span>
          </a>
        </li>
        <hr class="menu-line d-none" />
      </ul>
    </b-skeleton-wrapper>
  </div>
</template>

<script>
import { mapGetters } from 'vuex';
import { LOGOUT } from '@/core/services/store/auth.module';

export default {
  name: 'DPMenu',
  data() {
    return {
      isActive: false,
      isExactActive: false,
      navigate: '',
    };
  },
  computed: {
    ...mapGetters(['currentUser']),
  },
  methods: {
    hasActiveChildren(match) {
      return this.$route['path'].indexOf(match) !== -1;
    },
    getWelcomeText() {
      if (!this.currentUser || !this.currentUser.fullName) return;

      let w = 'Merhaba, ';

      var day = new Date();
      var hr = day.getHours();
      if (hr >= 6 && hr < 12) {
        w = 'Günaydın ';
      } else if (hr >= 12 && hr < 17) {
        w = 'İyi günler ';
      } else if (hr >= 17 && hr < 22) {
        w = 'İyi Akşamlar ';
      } else {
        w = 'İyi Geceler ';
      }
      return w + this.currentUser.fullName.split(' ')[0];
    },
    getUserRoleText() {
      if (!this.currentUser || !this.currentUser.fullName) return;

      return this.currentUser.user_group;
    },

    onLogout() {
      this.$store.dispatch(LOGOUT).then(() => (window.location = '/giris'));
    },
  },
};
</script>

<style lang="scss" scoped>
.menu-text {
  font-size: 12px !important;
  font-weight: 500 !important;
}

@media (min-width: 990px) {
  #logout {
    position: absolute;
    bottom: 1rem;
    left: 0;
    width: 100%;
  }
}

.menu-line {
  color: #e8e8e8;
  margin: 10px 25px;
}

.menu-item {
  margin-top: 0.8rem !important;

  .menu-link {
    border-radius: 0rem 0.46154rem 0.46154rem 0rem;
    padding: 1rem !important;

    .menu-text,
    .menu-icon {
      font-size: 1.23077rem !important;
      letter-spacing: 0.02462rem !important;
      font-weight: 400 !important;
    }
  }
}

.aside-menu .menu-nav > .menu-item.menu-item-active {
  .menu-link,
  .menu-text,
  .menu-icon {
    background-color: $dveb-info !important;
    color: $dveb-menu-text-color !important;
    font-size: 1.23077rem !important;
    font-weight: 600 !important;
  }

  .menu-link {
    &:hover {
      .menu-text,
      .menu-icon {
        background-color: $dveb-info !important;
        color: $dveb-menu-text-color !important;
      }
    }
  }
}

.aside-menu
  .menu-nav
  > .menu-item:not(.menu-item-parent):not(.menu-item-open):not(
    .menu-item-here
  ):not(.menu-item-active):hover {
  .menu-link,
  .menu-text,
  .menu-icon {
    background-color: $dveb-info !important;
    color: $dveb-menu-text-color !important;
  }
}
.aside-menu .menu-nav{
  padding-right: 15px;
}
.aside-menu .menu-nav > .menu-item.menu-item-open > .menu-link{
  background-color: $dveb-info;
  color: $dveb-menu-text-color !important;
}
.aside-menu .menu-nav > .menu-item.menu-item:hover > .menu-link .menu-icon{
  color: $dveb-menu-text-color !important;
}
.aside-menu .menu-nav > .menu-item.menu-item-open > .menu-link .menu-text{
  color: $dveb-menu-text-color !important;
}
.aside-menu .menu-nav > .menu-item.menu-item:hover > .menu-link{
  background-color: $dveb-info !important;
}
.aside-menu .menu-nav > .menu-item.menu-item:hover > .menu-link .menu-icon{
  background-color:$dveb-info !important;
  color: $dveb-menu-text-color  !important;
}
.aside-menu .menu-nav > .menu-item.menu-item:hover > .menu-link .menu-text{
  background-color: $dveb-info !important;
}
.aside-menu .menu-nav > .menu-item.menu-item-open > .menu-link .menu-icon{
  color: $dveb-menu-text-color !important;
}
.aside-menu .menu-nav > .menu-item .menu-submenu .menu-item:not(.menu-item-parent):not(.menu-item-open):not(.menu-item-here):not(.menu-item-active):hover > .menu-link{
  background-color: $dveb-info !important;
}
.aside-menu .menu-nav > .menu-item .menu-submenu .menu-item:not(.menu-item-parent):not(.menu-item-open):not(.menu-item-here):not(.menu-item-active):hover > .menu-link .menu-text{
  color: $dveb-menu-text-color !important;
} 
  
.aside-menu .menu-nav > .menu-item .menu-submenu .menu-item.menu-item-active > .menu-link{
  background-color: $dveb-info !important;
}
.menu-item.menu-item-active span{
  color: $dveb-menu-text-color !important;
}
.aside-menu .menu-nav > .menu-item .menu-submenu .menu-item > .menu-link .menu-bullet{
  color: $dveb-menu-text-color !important;
}
.aside-menu .menu-nav > .menu-item .menu-submenu .menu-item.menu-item-active > .menu-link .menu-bullet.menu-bullet-dot > span{
  color: $dveb-menu-text-color !important;
}
</style>
