var render = function render(){var _vm=this,_c=_vm._self._c;return _c('div',[_c('div',{staticClass:"font-size-md mt-5 welcomeText",staticStyle:{"color":"#01041b"}},[_vm._v(" "+_vm._s(_vm.getWelcomeText())+" "),_c('br'),_c('small',[_vm._v(_vm._s(_vm.getUserRoleText()))])]),_c('b-skeleton-wrapper',{attrs:{"loading":!_vm.currentUser || !_vm.currentUser.user_group},scopedSlots:_vm._u([{key:"loading",fn:function(){return [_c('b-card',{staticClass:"card card-custom gutter-b"},[_c('b-skeleton',{attrs:{"width":"85%"}}),_c('b-skeleton',{attrs:{"width":"55%"}}),_c('b-skeleton',{attrs:{"width":"70%"}})],1)]},proxy:true}])},[_c('ul',{staticClass:"menu-nav"},[_c('router-link',{attrs:{"to":"/"},scopedSlots:_vm._u([{key:"default",fn:function({ href, navigate, isActive, isExactActive }){return [_c('li',{staticClass:"menu-item",class:[
            _vm.$route.path == '/' && isActive && 'menu-item-active',
            _vm.$route.path == '/' && isExactActive && 'menu-item-active',
          ],attrs:{"aria-haspopup":"true","data-menu-toggle":"hover"}},[_c('a',{staticClass:"menu-link",attrs:{"href":href},on:{"click":navigate}},[_c('i',{staticClass:"menu-icon flaticon-home"}),_c('span',{staticClass:"menu-text"},[_vm._v("Ana Sayfa ")])])])]}}])}),_c('router-link',{attrs:{"to":"/mulkler"},scopedSlots:_vm._u([{key:"default",fn:function({ href, navigate, isActive, isExactActive }){return [_c('li',{staticClass:"menu-item",class:[
            _vm.$route.path == '/mulkler' && isActive && 'menu-item-active',
            _vm.$route.path == '/mulkler' && isExactActive && 'menu-item-active',
          ],attrs:{"aria-haspopup":"true","data-menu-toggle":"hover"}},[_c('a',{staticClass:"menu-link",attrs:{"href":href},on:{"click":navigate}},[_c('i',{staticClass:"menu-icon flaticon-home"}),_c('span',{staticClass:"menu-text"},[_vm._v("Mülkler ")])])])]}}])}),_c('router-link',{attrs:{"to":"/musteriler"},scopedSlots:_vm._u([{key:"default",fn:function({ href, navigate, isActive, isExactActive }){return [_c('li',{staticClass:"menu-item",class:[
            _vm.$route.path == '/musteriler' && isActive && 'menu-item-active',
            _vm.$route.path == '/musteriler' &&
              isExactActive &&
              'menu-item-active',
          ],attrs:{"aria-haspopup":"true","data-menu-toggle":"hover"}},[_c('a',{staticClass:"menu-link",attrs:{"href":href},on:{"click":navigate}},[_c('i',{staticClass:"menu-icon flaticon-home"}),_c('span',{staticClass:"menu-text"},[_vm._v("Müşteriler ")])])])]}}])}),_c('router-link',{attrs:{"to":"/is-ortaklari"},scopedSlots:_vm._u([{key:"default",fn:function({ href, navigate, isActive, isExactActive }){return [_c('li',{staticClass:"menu-item",class:[
            _vm.$route.path == '/is-ortaklari' && isActive && 'menu-item-active',
            _vm.$route.path == '/is-ortaklari' &&
              isExactActive &&
              'menu-item-active',
          ],attrs:{"aria-haspopup":"true","data-menu-toggle":"hover"}},[_c('a',{staticClass:"menu-link",attrs:{"href":href},on:{"click":navigate}},[_c('i',{staticClass:"menu-icon flaticon-home"}),_c('span',{staticClass:"menu-text"},[_vm._v("İş Ortakları ")])])])]}}])}),_c('router-link',{attrs:{"to":"/kiracilar"},scopedSlots:_vm._u([{key:"default",fn:function({ href, navigate, isActive, isExactActive }){return [_c('li',{staticClass:"menu-item",class:[
            _vm.$route.path == '/kiracilar' && isActive && 'menu-item-active',
            _vm.$route.path == '/kiracilar' &&
              isExactActive &&
              'menu-item-active',
          ],attrs:{"aria-haspopup":"true","data-menu-toggle":"hover"}},[_c('a',{staticClass:"menu-link",attrs:{"href":href},on:{"click":navigate}},[_c('i',{staticClass:"menu-icon flaticon-home"}),_c('span',{staticClass:"menu-text"},[_vm._v("Kiracılar ")])])])]}}])}),_c('router-link',{attrs:{"to":"/hatirlatmalar"},scopedSlots:_vm._u([{key:"default",fn:function({ href, navigate, isActive, isExactActive }){return [_c('li',{staticClass:"menu-item",class:[
            _vm.$route.path == '/hatirlatmalar' && isActive && 'menu-item-active',
            _vm.$route.path == '/hatirlatmalar' &&
              isExactActive &&
              'menu-item-active',
          ],attrs:{"aria-haspopup":"true","data-menu-toggle":"hover"}},[_c('a',{staticClass:"menu-link",attrs:{"href":href},on:{"click":navigate}},[_c('i',{staticClass:"menu-icon flaticon-home"}),_c('span',{staticClass:"menu-text"},[_vm._v("Hatırlatmalar ")])])])]}}])}),_c('router-link',{attrs:{"to":"/paketler/paket/"},scopedSlots:_vm._u([{key:"default",fn:function({ href, navigate, isActive, isExactActive }){return [_c('li',{staticClass:"menu-item",class:[
            _vm.$route.path == '/paket' && isActive && 'menu-item-active',
            _vm.$route.path == '/paket' &&
              isExactActive &&
              'menu-item-active',
          ],attrs:{"aria-haspopup":"true","data-menu-toggle":"hover"}},[_c('a',{staticClass:"menu-link",attrs:{"href":href},on:{"click":navigate}},[_c('i',{staticClass:"menu-icon flaticon-home"}),_c('span',{staticClass:"menu-text"},[_vm._v("Paketler ")])])])]}}])}),_c('router-link',{attrs:{"to":"/raporlar/rapor/"},scopedSlots:_vm._u([{key:"default",fn:function({ href, navigate, isActive, isExactActive }){return [_c('li',{staticClass:"menu-item",class:[
            _vm.$route.path == '/rapor' && isActive && 'menu-item-active',
            _vm.$route.path == '/rapor' &&
              isExactActive &&
              'menu-item-active',
          ],attrs:{"aria-haspopup":"true","data-menu-toggle":"hover"}},[_c('a',{staticClass:"menu-link",attrs:{"href":href},on:{"click":navigate}},[_c('i',{staticClass:"menu-icon flaticon-home"}),_c('span',{staticClass:"menu-text"},[_vm._v("Raporlar ")])])])]}}])}),_c('li',{staticClass:"menu-item menu-item-submenu",class:{
          'menu-item-open': _vm.hasActiveChildren('/manage'),
        },attrs:{"aria-haspopup":"true","data-menu-toggle":"hover"}},[_c('a',{staticClass:"menu-link menu-toggle",attrs:{"href":"#"}},[_c('i',{staticClass:"menu-icon flaticon-home"}),_c('span',{staticClass:"menu-text"},[_vm._v("Takip")]),_c('i',{staticClass:"menu-arrow"})]),_c('div',{staticClass:"menu-submenu",attrs:{"kt-hidden-height":"240"}},[_c('span',{staticClass:"menu-arrow"}),_c('ul',{staticClass:"menu-subnav"},[_c('li',{staticClass:"menu-item menu-item-parent",attrs:{"aria-haspopup":"true"}},[_c('span',{staticClass:"menu-link"},[_c('span',{staticClass:"menu-text"},[_vm._v("Takip")])])]),_c('router-link',{attrs:{"to":"/takip/istakip"},scopedSlots:_vm._u([{key:"default",fn:function({ href, navigate, isActive, isExactActive }){return [_c('li',{staticClass:"menu-item",class:[
                  isActive && 'menu-item-active',
                  isExactActive && 'menu-item-active',
                ],attrs:{"aria-haspopup":"true","data-menu-toggle":"hover"}},[_c('a',{staticClass:"menu-link",attrs:{"href":href},on:{"click":navigate}},[_c('i',{staticClass:"menu-bullet menu-bullet-dot"},[_c('span')]),_c('span',{staticClass:"menu-text"},[_vm._v("İş Takip ")])])])]}}])})],1)])]),_c('router-link',{attrs:{"to":"/sistem-kullanicilari"},scopedSlots:_vm._u([{key:"default",fn:function({ href, navigate, isActive, isExactActive }){return [_c('li',{staticClass:"menu-item",class:[
            isActive && 'menu-item-active',
            isExactActive && 'menu-item-active',
          ],attrs:{"aria-haspopup":"true","data-menu-toggle":"hover"}},[_c('a',{staticClass:"menu-link",attrs:{"href":href},on:{"click":navigate}},[_c('i',{staticClass:"menu-icon flaticon-network"}),_c('span',{staticClass:"menu-text"},[_vm._v("Sistem Kullanıcı Listesi")])])])]}}])}),_c('li',{staticClass:"menu-item menu-item-submenu",class:{
          'menu-item-open': _vm.hasActiveChildren('/manage'),
        },attrs:{"aria-haspopup":"true","data-menu-toggle":"hover"}},[_c('a',{staticClass:"menu-link menu-toggle",attrs:{"href":"#"}},[_c('i',{staticClass:"menu-icon flaticon-home"}),_c('span',{staticClass:"menu-text"},[_vm._v("Ödemeler")]),_c('i',{staticClass:"menu-arrow"})]),_c('div',{staticClass:"menu-submenu",attrs:{"kt-hidden-height":"240"}},[_c('span',{staticClass:"menu-arrow"}),_c('ul',{staticClass:"menu-subnav"},[_c('li',{staticClass:"menu-item menu-item-parent",attrs:{"aria-haspopup":"true"}},[_c('span',{staticClass:"menu-link"},[_c('span',{staticClass:"menu-text"},[_vm._v("Ödemeler")])])]),_c('router-link',{attrs:{"to":"/odemeler/musteri-odemeler"},scopedSlots:_vm._u([{key:"default",fn:function({ href, navigate, isActive, isExactActive }){return [_c('li',{staticClass:"menu-item",class:[
                  isActive && 'menu-item-active',
                  isExactActive && 'menu-item-active',
                ],attrs:{"aria-haspopup":"true","data-menu-toggle":"hover"}},[_c('a',{staticClass:"menu-link",attrs:{"href":href},on:{"click":navigate}},[_c('i',{staticClass:"menu-bullet menu-bullet-dot"},[_c('span')]),_c('span',{staticClass:"menu-text"},[_vm._v("Müşteri Ödeme ")])])])]}}])}),_c('router-link',{attrs:{"to":"/odemeler/sigorta-odemeler"},scopedSlots:_vm._u([{key:"default",fn:function({ href, navigate, isActive, isExactActive }){return [_c('li',{staticClass:"menu-item",class:[
                  isActive && 'menu-item-active',
                  isExactActive && 'menu-item-active',
                ],attrs:{"aria-haspopup":"true","data-menu-toggle":"hover"}},[_c('a',{staticClass:"menu-link",attrs:{"href":href},on:{"click":navigate}},[_c('i',{staticClass:"menu-bullet menu-bullet-dot"},[_c('span')]),_c('span',{staticClass:"menu-text"},[_vm._v("Sigorta Ödemeleri ")])])])]}}])}),_c('router-link',{attrs:{"to":"/odemeler/vergi-odemeler"},scopedSlots:_vm._u([{key:"default",fn:function({ href, navigate, isActive, isExactActive }){return [_c('li',{staticClass:"menu-item",class:[
                  isActive && 'menu-item-active',
                  isExactActive && 'menu-item-active',
                ],attrs:{"aria-haspopup":"true","data-menu-toggle":"hover"}},[_c('a',{staticClass:"menu-link",attrs:{"href":href},on:{"click":navigate}},[_c('i',{staticClass:"menu-bullet menu-bullet-dot"},[_c('span')]),_c('span',{staticClass:"menu-text"},[_vm._v("Vergi Ödemeleri ")])])])]}}])}),_c('router-link',{attrs:{"to":"/odemeler/kiraci-odemeler"},scopedSlots:_vm._u([{key:"default",fn:function({ href, navigate, isActive, isExactActive }){return [_c('li',{staticClass:"menu-item",class:[
                  isActive && 'menu-item-active',
                  isExactActive && 'menu-item-active',
                ],attrs:{"aria-haspopup":"true","data-menu-toggle":"hover"}},[_c('a',{staticClass:"menu-link",attrs:{"href":href},on:{"click":navigate}},[_c('i',{staticClass:"menu-bullet menu-bullet-dot"},[_c('span')]),_c('span',{staticClass:"menu-text"},[_vm._v("Kiracı Ödeme ")])])])]}}])}),_c('router-link',{attrs:{"to":"/odemeler/aidat-odemeler"},scopedSlots:_vm._u([{key:"default",fn:function({ href, navigate, isActive, isExactActive }){return [_c('li',{staticClass:"menu-item",class:[
                  isActive && 'menu-item-active',
                  isExactActive && 'menu-item-active',
                ],attrs:{"aria-haspopup":"true","data-menu-toggle":"hover"}},[_c('a',{staticClass:"menu-link",attrs:{"href":href},on:{"click":navigate}},[_c('i',{staticClass:"menu-bullet menu-bullet-dot"},[_c('span')]),_c('span',{staticClass:"menu-text"},[_vm._v("Aidat Ödemeleri")])])])]}}])}),_c('router-link',{attrs:{"to":"/odemeler/isortaklari-odemeler"},scopedSlots:_vm._u([{key:"default",fn:function({ href, navigate, isActive, isExactActive }){return [_c('li',{staticClass:"menu-item",class:[
                  isActive && 'menu-item-active',
                  isExactActive && 'menu-item-active',
                ],attrs:{"aria-haspopup":"true","data-menu-toggle":"hover"}},[_c('a',{staticClass:"menu-link",attrs:{"href":href},on:{"click":navigate}},[_c('i',{staticClass:"menu-bullet menu-bullet-dot"},[_c('span')]),_c('span',{staticClass:"menu-text"},[_vm._v("İş Ortakları Ödemeler")])])])]}}])})],1)])]),_c('li',{staticClass:"menu-item menu-item-submenu",class:{
          'menu-item-open': _vm.hasActiveChildren('/manage'),
        },attrs:{"aria-haspopup":"true","data-menu-toggle":"hover"}},[_c('a',{staticClass:"menu-link menu-toggle",attrs:{"href":"#"}},[_c('i',{staticClass:"menu-icon flaticon-home"}),_c('span',{staticClass:"menu-text"},[_vm._v("Yönetim")]),_c('i',{staticClass:"menu-arrow"})]),_c('div',{staticClass:"menu-submenu",attrs:{"kt-hidden-height":"240"}},[_c('span',{staticClass:"menu-arrow"}),_c('ul',{staticClass:"menu-subnav"},[_c('li',{staticClass:"menu-item menu-item-parent",attrs:{"aria-haspopup":"true"}},[_c('span',{staticClass:"menu-link"},[_c('span',{staticClass:"menu-text"},[_vm._v("Yönetim")])])]),_c('router-link',{attrs:{"to":"/yonetim/musteriler/"},scopedSlots:_vm._u([{key:"default",fn:function({ href, navigate, isActive, isExactActive }){return [_c('li',{staticClass:"menu-item",class:[
                  isActive && 'menu-item-active',
                  isExactActive && 'menu-item-active',
                ],attrs:{"aria-haspopup":"true","data-menu-toggle":"hover"}},[_c('a',{staticClass:"menu-link",attrs:{"href":href},on:{"click":navigate}},[_c('i',{staticClass:"menu-bullet menu-bullet-dot"},[_c('span')]),_c('span',{staticClass:"menu-text"},[_vm._v("Müşteriler ")])])])]}}])})],1)])]),_c('router-link',{attrs:{"to":"/teknik-destek"},scopedSlots:_vm._u([{key:"default",fn:function({ href, navigate, isActive, isExactActive }){return [_c('li',{staticClass:"menu-item",class:[
            isActive && 'menu-item-active',
            isExactActive && 'menu-item-active',
          ],attrs:{"aria-haspopup":"true","data-menu-toggle":"hover"}},[_c('a',{staticClass:"menu-link",attrs:{"href":href},on:{"click":navigate}},[_c('i',{staticClass:"menu-icon flaticon-support"}),_c('span',{staticClass:"menu-text"},[_vm._v("Teknik Destek")])])])]}}])}),_c('li',{staticClass:"menu-item",class:[
          _vm.isActive && 'menu-item-active',
          _vm.isExactActive && 'menu-item-active',
        ],attrs:{"aria-haspopup":"true","data-menu-toggle":"hover"}},[_c('a',{staticClass:"menu-link",on:{"click":_vm.onLogout}},[_c('i',{staticClass:"menu-icon flaticon-logout"}),_c('span',{staticClass:"menu-text"},[_vm._v("Çıkış Yap")])])]),_c('hr',{staticClass:"menu-line d-none"})],1)])],1)
}
var staticRenderFns = []

export { render, staticRenderFns }